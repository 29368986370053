
@media (min-width: 1070px) {
    .artwork-gallery {
        width: 891px;
    }
    .collection {
        width: 891px;
    }
}

@media (min-width: 1400px) {
    .artwork-gallery {
        width: 1188px;
    }
    .collection {
        width: 1188px;
    }
    .artwork-gallery .hero-card {
        width: 1170px;
        height: 780px
    }

    .artwork-gallery .hero-card .live-frame {
        width: 780px;
    }

    .artwork-gallery .hero-card .live-frame .mint-pending .status-text {
        bottom: 150px;
        font-size: 16pt;
    }
}

@media (min-width: 1860px) {
    .artwork-gallery {
        width: initial;
    }
    .collection {
        width: initial;
    }
}
