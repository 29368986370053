@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap);

.edit-artwork {
    width: 90%;
}

.edit-artwork .view-artwork-link {
    margin: 5px;
}

.edit-artwork .store-button {
    margin-top: 2em;
}

.edit-artwork .store-button .store-error {
    color: red;
    font-weight: 600;
    margin-top: 5px;
}
.edit-artwork .store-button .store-warning {
    color: orange;
    font-weight: 600;
    margin-top: 5px;
}

.edit-artwork .store-button button {
    height: 32px;
    width: 240px;
    font-weight: 800;
    font-size: 18px;
    border-radius: 3px;
}

.edit-artwork h3 {
    margin-top: 5em;    
}

.edit-artwork label {
    margin-top: 20px;
    display: block;
    width: 100%;
}

.edit-artwork input {
    display: block;
    width: 100%;
}

.edit-artwork input.number {
    display: block;
    width: 100px;
}

.edit-artwork input.checkbox  {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}

.edit-artwork textarea {
    display: block;
    width: 100%;
}

.edit-artwork .is_paused label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .isVisible label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .selectSeedOnMint label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .rerolls_enabled label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .royalties {
    border: 1px solid grey;
    padding: 5px;
}

.edit-artwork .royalties label {
    display: inline;
}

.edit-artwork .royalties input {
    display: inline-block;
    width: 50px;
    margin-left: 5px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.edit-artwork .royalties input.address {
    width: 300px;
}


.edit-artwork .source-code {
    margin-bottom: inherit;

    display: flex;
    flex-flow: column;
    min-height: 200px;
    height: 700px;
    resize: both;
    overflow: auto;
  
    border: 1px solid black;
    padding: 0px;
}

.edit-artwork .no-change-scripttype-warning {
    display: inline-block;
    font-size: 0.8em;
    color: rgb(48, 133, 48);
    margin-left: 5px;
}

.edit-artwork .artist-tools-github-link {
    font-weight: 600;
}

.edit-artwork .artist-tools-github-link a {
    text-decoration: underline;
}

.edit-artwork .script-editor button.show-preview-button {
    width: 200px;
    height: 24px;
    margin-top: 5px;
    float: right;
}

.edit-artwork .artwork-script-preview {
    width: 400px;
    border: 3px solid white;
    background-color: white;
    position: relative;
}

.edit-artwork button.reload-button {
    position: absolute;
    width: 30px;
    height: 30px;
    right: -40px;
    fill: white;
    stroke: white;
}



.edit-artwork .custom-token-sample {
    box-sizing: border-box;
    resize: none;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    resize: none;
    width: 100%;
    margin: 0;
}

.edit-artwork .source-code textarea {
    box-sizing: border-box;

    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    resize: none;
    width: 100%;
    margin: 0;
    border: 0;
}


.edit-artwork .source-code .token-sample {
    /*flex: 0 1 auto;*/
    flex: 0 0.1 145px;
    border-bottom: 1px solid rgb(200,200,200);

    background: white;
    color: grey;
        /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}


.edit-artwork .source-code .editable {
    flex: 1 1 auto;
}

.edit-artwork .extra-button {
    margin-top: 3em;
    margin-right: 1em;
    display: inline-block;
}

.edit-artwork .extra-button button {
    height: 32px;
    width: 240px;
    font-weight: 800;
    font-size: 18px;
    border-radius: 3px;
}


.manage-contract label {
    margin-top: 20px;
    display: block;
    width: 100%;
}

.manage-contract div {
    margin-bottom: 20px;
    display: block;
    width: 100%;
}

.manage-contract input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.manage-contract .base-uris {
    margin-top: 20px;
    padding: 0;
} 

.manage-contract .base-uris label {
    margin: 0;
    display: inline-block;
}

.manage-contract .base-uris input {
    margin: 0;
    display: inline-block;
}

.manage-contract .platform-cut {
    margin-top: 20px;
}

.manage-contract .platform-cut label {
    margin: 0;
    display: inline-block;
}

.manage-contract .platform-cut input {
    margin: 0;
    display: inline-block;
}

.manage-contract .add-test-artwork {
    margin-top: 20px;
}

.add-artwork input {
    width: 300px;
    margin: 10px;
}


/*@import url('https://fonts.googleapis.com/css2?family=Courier');*/

html, body{
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: Nunito, sans-serif;
    font-size: 100%;
    color: rgb(29,29,29);
    background-color:rgb(242, 242, 242);
    /*background-attachment: fixed;*/
    max-width: 100%;
    overflow-x: hidden;
}


#root {
    height: 100%;
    width: 100%;
    background: linear-gradient(white 200px, rgb(242, 242, 242) 400px ) no-repeat;
}

#root:after{
    content: "";
    display: table;
    clear: both;
}

nav {
    position: absolute;
    right: 10px;
    top: 10px;
}


.site-container{
    position: relative;
    padding-bottom: 120px;
    padding-top: 218px;
    margin-left: 90px;
    margin-right: 90px;
}



.site-root-link {
    position: absolute;
    width: 39px;
    /* left: max(10%, 90px); */
    left: -70px;
    top: 26px;
    padding: 10px;
}


.footer {
    width: 100%;
    /*height: 78px;*/
    margin-top: 40px;
    padding-top: 20px;

    background: linear-gradient(rgb(232, 232, 232, 0.7), rgb(232, 232, 232, 1) 50%);

    /*clear: both;*/

    /*position: absolute;
    bottom: 0px;*/
    border-top: 1px solid white;

    color: #888;
    font-weight: 100;
    font-size: 14pt;

    position: relative;
    bottom: 120px;

    padding-top: 60px;
    padding-bottom: 60px;
}

.footer .elements {         
    display: flex;
}

.footer a {
    color: #333;
}


.footer a:visited {
    color: #333;
}

.footer div.elements:first-child {
    margin-left: 50px;
}

.footer div.elements:last-child {
    margin-right: 50px;
}

.footer .ewy-logo {
    margin-top: 0px;
    margin-right: 36px;
    width: 58px;
    height: 58px;
}

.footer .credits {
    margin-top: 9px;
    text-align: left;
    font-size: 11pt;
}

.footer .external-links  {
    margin-top: 11px;
    /*margin-right: 50px;*/
    margin-left: auto;
}

.footer .external-links a {
    color: #888;    
}

.footer .external-links a:visited {
    color: #888;    
}

.footer .external-links span {
    /* border: 1px solid; */
    position: relative;
    bottom: 12px;
}

.footer .newsletter {
    margin-top: 11px;
    margin-left: auto;
    margin-right: -9px;
}

.footer .follow-us {
    margin-right: 4px;
}

.footer .external-links .logo {
    width: 36px;
    height: 36px;
    margin-left: 18px;
}




.logo-mandelbrot-watermark
{
    content: "./images/logoMandlebrotWhiteNoBorder.svg";
    position: fixed;
    opacity: 100%;
    top: 68px;
    right: 68px;
    height: 117px;
}

/*
.logoMandelbrotGross{
    background-image: url("/images/logoMandelbrotGross.svg");
}*/

nav ul {
    font-size: 14pt;
    font-weight: 300;
    margin-top: 63px;
    margin-bottom: 135px;
    text-align: right;
}

nav ul li {
    display: inline-block;
    margin: 0 0 0 48px;
}


nav ul li .active {
    color: rgb(153,153,153)
}

a {
    color: rgb(29,29,29);
    text-decoration: none;
}

button {
    background-color: black;
    color: white;
    font-family: Nunito;
    font-weight: 600;
    border-radius: 3px;

    cursor: pointer;

    /* disable default button rendering */
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
}


button.looks-like-link {
    background-color: inherit;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    border: inherit;
}


button:disabled {
    background-color: gray;
    color:rgb(220, 220, 220)
}


button.looks-like-link:disabled {
    background-color: inherit;
    color: inherit;
}

.artwork-card a:hover {
    /* the whole artwork-card is a link, so turn off colorising */
    color: rgb(29,29,29);
}

a:hover{
    color: #a70000;
}

h1 {
    font-size: 25pt;
    font-weight: 200;
}

button.looks-like-link.login-or-disconnect {
    padding: 0;
    margin: 0;
    top: 0;
}

.connect-wallet {
    display: inline-block;
    position: relative;
    margin-left: 18px;
    top: 7px;
    width: 142px;
}

.connect-wallet button {
    color: inherit;
    border: none;
    background-color: inherit;
}

.connect-wallet {
    color: inherit;
    border: none;
    background-color: inherit;
}


.connect-wallet .wallet-icon {
    width: 38px;
    height: 35px;
    /* margin-top: 5px; */
    margin-left: 10px; 
    overflow: visible;
    position: relative;
    top: 4px;
    cursor: pointer;
}

.connect-wallet .avatar {
    margin-left: 10px; 
}


.wallet-dropdown {
    position: relative;
}

.connect-wallet .left-text {
    font-size: 8pt;
    font-weight: 600;
    position: relative;
    top: -9px;
}

.connect-wallet button {
    font-size: 8pt;
    font-weight: 600;
    position: relative;
    top: -9px;
}


.wallet-dropdown .menu {
    position: absolute;
    width: 180px;
    top: -10px;
    left: 96px;
    /*border: 1px solid green;*/
    text-align: center;
}

.wallet-dropdown .menu .body {
    background-color: #1a1a1a;
}

.wallet-dropdown .text {
    display: block;
    font-size: 8pt;
    font-weight: 600;
    color: #999;
    height: 36px;
    line-height: 36px;
    top: 0;
}

.wallet-dropdown button.text {
    width: 180px;
    height: 36px;
    padding-top: 3px;
}

.wallet-dropdown .separator {
    width: 175px;
    margin-left: 2.5px;
    border-top: 1px solid #808080
}


.body-text {
    font-size: 14pt;
    font-weight: 200;
    margin-bottom: 3em;
}

.body-text .important {
    font-weight: 400;
}

.home .body-text {
    margin-bottom: inherit;
}


.body-text a {
    text-decoration: underline;
}

.body-text h2 {
    font-size: 18pt;
    font-weight: 300;
    margin-top: 2em;
}

.body-text h3 {
    font-size: 14pt;
    font-weight: 300;
    margin-top: 0px;
    margin-bottom: 0px;
}


.artworks {
    display: flex;    
    justify-content: center;
    flex-wrap: wrap;

    padding: 0px 0px;
    margin: 37px 0px;
}


/* artwork card */

.artwork-card {
    display: inline-block;
    padding: 0px 0px;
    margin: auto 11px 22px 11px;
    width: 280px;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;

    border: 3px solid white;
    position: relative;
}


.artwork-card .avatar {
    position: relative;
    left: 113px;
    top: -27px;
    width: 54px;
    height: 54px;
}

.avatar {
    object-fit: cover;
    border-radius: 50%;
}


.artwork-card .mint-thumbnail {
    display: block;
    width: 280px;
    /*height: 270px;*/
    overflow: hidden;
}

.mint-thumbnail img {
    width: 100%;
    height: 100%;
    display: block;
}


.mint-thumbnail .gradient-overlay {
    position:relative;
    display:inline-block;
}

.mint-thumbnail .gradient-overlay:after {
    content:'';
    position:absolute;
    left:0; 
    top:0;
    width:100%; 
    height:100%;
    display:inline-block;
    background: linear-gradient(rgba(255, 255, 255, 0), 80%, rgba(242,242,242,1.0));
}

.mint-thumbnail .grid-loader {
    position: absolute;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.mint-thumbnail .tree-loader {
    /* nothing here */
}

.artwork-card .artwork-details {
    height: 116px;
    margin-top: -28px;
}

.artwork-card .artwork-details .title {
    text-align: center;
    margin-top: 0px;
    font-size: 14pt;
    font-weight: 300;
}

.artwork-card .artwork-details .artist {
    text-align: center;
    margin-top: 5px;
    font-size: 9pt;
    font-weight: 600;
}

.artwork-card .artwork-mint-progress {
    padding: 9px;
    position: absolute;
    bottom: 0;
    font-size: 10pt;
    width: 262px;
    left: 0;
}

div .progress-bar-box {
    width: 100%;
    height: 9px;
    margin: 3px 0 3px;
    overflow: hidden;
    border: 1px solid black;
}

/*
**********************************************
Artwork gallery
**********************************************
*/

.artwork-gallery {
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    margin-bottom: 40px;
}

.artwork-gallery .hero-card {
    border-radius: 9px;
    height: 578px;
    width: 863px;
    border: 5px solid white;
    background-color: white;
    position: relative;
    /*margin-left: auto;*/
    margin-right: auto;
}

.artwork-gallery .hero-card .live-frame {
    width: 578px;
    border-radius: 6.5px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.artwork-gallery .hero-card .artwork-details {
    width: 275px;
    height: 100%;
    position: relative;
}

.artwork-gallery .hero-card .edit-button a {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 40px;
    height: 40px;
    font-size: 30px;
    padding: 15px;
    text-align: center;
    border-radius: 4px;
    background-color: white;
}


.artwork-gallery .hero-card .live-frame .mint-pending .status-text {
    font-weight: 200;
    font-size: 14pt;
    text-align: center;
    position: relative;
    bottom: 100px;
}

.artwork-gallery .hero-card .live-frame .mint-pending .ewy-logo {
    /* container height = 578 */
    width: 300px;
    margin-top: 105px;    
    margin-bottom: 40px;
    -webkit-animation: kf-fade-in-out 5s ease-in-out forwards infinite;
            animation: kf-fade-in-out 5s ease-in-out forwards infinite;
    /* start at 40% */
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
    position: relative;
}

.fade-in-out-2s {
    -webkit-animation: kf-fade-in-out 2s ease-in-out forwards infinite;
            animation: kf-fade-in-out 2s ease-in-out forwards infinite;
}

.fade-in-out-4s {
    -webkit-animation: kf-fade-in-out 4s ease-in-out forwards infinite;
            animation: kf-fade-in-out 4s ease-in-out forwards infinite;
}

@-webkit-keyframes kf-fade-in-out {
    0% { opacity: 0.06; }
    50% { opacity: 0.5; }
    100% { opacity: 0.06; }
}

@keyframes kf-fade-in-out {
    0% { opacity: 0.06; }
    50% { opacity: 0.5; }
    100% { opacity: 0.06; }
}

.token-not-found {
    width: 100%;
    padding-top: 20px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
}

.artwork-gallery .hero-card .artwork-details img.avatar {
    width: 45px;
    position: relative;
    left: 115px;
}
.artwork-gallery .hero-card .artwork-details .title {
    font-size: 14pt;
    text-align: center;
}

.artwork-gallery .hero-card .artwork-details .artist {
    font-size: 9pt;
    font-weight: 600;
    text-align: center;
}

.hero-card .artwork-details .artist-link {
    width: 18px;
    margin-left: 5px;
    position: relative;
    top: 4px;
}

.artwork-gallery .hero-card .artwork-details .description {
    white-space: pre-wrap;
    font-size: 12pt;
    font-weight: 200;
    line-height: 1.1em;
    max-height: 212px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.artwork-gallery .hero-card .artwork-details .description .more-button {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: white;
    color: #999;
}

.artwork-gallery .hero-card .artwork-details .more-indicator {
    position: absolute;
    top: 118px;
    left: -2px;
    z-index: 2;
}

.modal-inner.description-more {
    white-space: pre-wrap;
    background-color: white;
    font-size: 12pt;
    font-weight: 200;
    line-height: 1.1em;
    z-index: 2;
    max-height: 60%;
}

.modal-inner.description-more button.looks-like-link.close-button {
    position: absolute;
    color: #999;
    border: none;
    margin-top: 0px;
    top: 7px;
    padding: 10px;
    left: 4px;
}

.artwork-gallery .hero-card .artwork-details .rights-header {
    font-size: 9pt;
    text-align: left;
    font-weight: 400;
    height: 18px;
    /*border: 1px solid black;*/
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.artwork-gallery .hero-card .artwork-details .rights {
    font-size: 9pt;
    text-align: left;
    font-weight: 200;
    height: 20px;
    /*border: 1px solid black;*/
    margin-top: 0px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.artwork-gallery .hero-card .artwork-details .align-to-bottom {
    position: absolute;
    width: 100%;
    bottom: 0px;
}

.artwork-gallery .hero-card .artwork-details .artwork-mint-progress {
    margin: auto;
    width: 238px;
    margin-bottom: 6px;
}

.artwork-gallery .hero-card .mint-ui {
    width: 100%;
    height: 92px;
    text-align: center;
}


.artwork-gallery .hero-card .mint-ui .error {
    margin: 5px;
}

.artwork-gallery .hero-card .mint-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.artwork-gallery .hero-card .mint-buttons button {
    height: 32px;
    font-size: 18px;
    font-weight: 800;
}


.artwork-gallery .hero-card .mint-ui .reload-button {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    fill: white;
    stroke: white;

    font-size: 18px;
    font-weight: 800;
}

.artwork-gallery .hero-card .mint-ui .mint-button {
    width: 191px;
}

.artwork-gallery .hero-card .mint-ui .preview-start-button {
    width: 191px;
}

.artwork-gallery .hero-card .live-frame iframe {
    width: 100%;
}

.artwork-gallery .mints-page-selector {
    margin-top: 20px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}

.artwork-gallery .mints-page-selector button {
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 0px;
    margin-right: 0px;
    color: #333;
}

.artwork-gallery .mints-page-selector button.looks-like-link.next-prev {
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 800;
    color: #999;
}

.artwork-gallery .mints-page-selector button.looks-like-link.current {
    text-decoration: underline;
    text-decoration-thickness: 0.1em;
    text-underline-offset: 1px;
}




.mints {
    margin-top: 18px;
    display: grid;
    grid-template-columns: repeat(auto-fit, 297px);
    justify-content: left;
}

.mints .mint-static {
    display: inline-block;
    margin: auto 0 20px 0;
    width: 275px;
    border: 1.5px solid white;
    border-radius: 9px;
    background-color: white;
    position: relative; 
}

.mints .mint-static a:hover {
    /* the whole artwork-card is a link, so turn off colorising */
    color: rgb(29,29,29);
}

.mints .mint-static .mint-thumbnail {
    display: block;
    position: relative;
    width: 272px;
    border: 1.5px solid white;
    border-radius: 7.5px;
    overflow: hidden;
}

.mints .mint-static .details {
    margin-left: 14px;
    margin-top: 0px;
    height: 90px;
    width: 160px;
    /* don't know why I need this: */
    text-align: left;
}

.mints .mint-static .buttons {
    position: absolute;
    right: 8.5px;
    bottom: 6px;
    display: flex;
}

.mints .mint-static .buttons a {
    margin-left: 7px;
}

.mints .mint-static .details .mint-number {
    padding-top: 9px;
    font-weight: 200;
    font-size: 18px;
    width: 240px;
    display: block;
}

.mints .mint-static .details .title {
    padding-top: 0px;
    margin-top: 0px;
    font-weight: 600;
    font-size: 12px;
    display: block;
}

.mints .mint-static .details img.avatar {
    width: 22px;
    height: 22px;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.mints .mint-static .details .owner {
    padding-top: 8px;
    width: 128px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 12px;
    display: inline-block;
    color: rgb(128,128,128);
}


.error {
    color: red;
}


.mint-live {
    display: block;
    width: 100%;
    position: relative;
}

.mint-live iframe {
    display: block;
}

.mint-live > div.aspect-ratio-forcer {
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
}

.collection {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
}

.collection .details {
    text-align: center;
}

.collection img.avatar {
    width: 120px;
    height: 120px;
}

.collection .tezosAddress {
    font-weight: 600;
    font-size: 9pt;
    margin-top: 16px;
    color: #666;
}

.collection .h1 {
    font-weight: 700;
    font-size: 17pt;
    color: #999;
    margin-top: 10px;
}

.artifact {
    margin-bottom: 40px;
}

.artifact .live-frame {
    display: flex;
    align-items: center;

    padding-top: 60px;
    padding-bottom: 60px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    border-radius: 9px;
    overflow: hidden;
    border: 3px solid white;
    /* fix safari rounded border overflow */
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}

.artifact .live-frame .next-prev {
    width: 64px;
    min-width: 64px;
}

.artifact .live-frame .next-prev img {
    padding: 10px;
    width: 18px;
}

.artifact .mint-live {
}


.artifact .details {
    box-sizing: border-box;
    margin-top: 7px;
    border-radius: 9px;
    border: 3px solid white;
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.artifact .details .item {
    margin: 15px 20px 15px 20px;
    font-size: 10pt;
}

.artifact .description {
    margin-top: 7px;
    box-sizing: border-box;
    padding: 20px;
    -webkit-column-count: 1;
            column-count: 1;
    white-space: pre-wrap;
    background-color: white;
    border: 3px solid white;
    border-radius: 9px;
    font-weight: 300;
    font-size: 12pt;
    width: 100%;
}

/*
.artifact .details .buttons-container {
    position: relative;
    width: 90px;
    height: 40px;
}


.artifact .details .buttons {
    position: absolute;
    margin: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    height: 22px;
}*/

.artifact .details .buttons a {
    display: inline-block;
    margin-left: 7px;
}


.artifact .details .item .label {
    color: #999;
    font-weight: 600;
}

.artifact .details .item .data {
    font-weight: 600;    
    height: 24px;
    line-height: 24px;
    color: #333;
}

.artifact .details .item .data .avatar {
    width: 22px;
    height: 22px;
    vertical-align: top;
    margin-right: 5px;
}


.mailing-list-signup {
    position: relative;
    /*width: 800px;*/
    width: 584px;
    height: 760px;
    max-width: 100%;
    margin-bottom: -30px;
    top: -30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.impressum {
    font-weight: 200;
}

.impressum h2 {
    font-size: 16pt;
    font-weight: 200;
}

.impressum a {
    text-decoration: underline;
}

.impressum .secondary div {
    margin-bottom: 1em;
}

.modal-outer {
    overflow-y: auto;
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    /*left: 40px;
    right: 40px;
    top: 40px;
    bottom: 40px;*/
    /*
    left: 50%;
    transform: translateX(-50%);
    top: 80px;
    bottom: 80px;*/
}

.modal-inner {
    text-align: justify;
    font-weight: 200;

    background-color: white;
    border: 6px solid #999;
    border-radius: 12px;
    margin: 0px;
    padding: 20px 40px 60px 40px;
    max-height: 80vh;
    overflow-y: auto;
    position: absolute;
    left: 20%;
    -webkit-transform: translateX(-12%);
            transform: translateX(-12%);
    top: 80px;

}

.modal-inner div {
    margin-top: 1em;
}

.modal-inner .important {
    font-weight: 400;
}

.modal-inner input {
    margin-right: 10px;
}

.modal-inner .buttons {
    display: flex;
    justify-content: space-around;
}

.modal-inner button {
    height: 32px;
    font-size: 12pt;
    font-weight: 800;
    padding: 0px 20px 0px 20px;
    margin-top: 20px;
}

.confirm-mint-modal .artwork-title {
    font-style: italic;
}

.confirm-mint-modal .code {
    font-family: 'Courier New', Courier, monospace;
    overflow-wrap: break-word;
}

.exhibition {
    margin-bottom: 40px;
}

.exhibition h1 {
    font-size: 28pt;
    font-weight: 200;
    margin-bottom: 0;
}

.exhibition .curator {
    font-size: 10pt;
    font-weight: 600;
}

.exhibition .curation-statement {
    margin-top: 1.5em;
    margin-bottom: 2em;
    font-size: 15pt;
    font-weight: 200;
}



.exhibition .curator .avatar {
    position: relative;
    top: 6px;
    margin-right: 5px;
}

.exhibition-items .item {
    /*height: 172px;*/
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    background-color: white;
    margin-top: 10px;
    padding-top: 8px;
    padding-left: 15px;
    padding-bottom: 8px;
    padding-right: 20px;
}

.exhibition-items a:hover {
    /* turn off colorising */
    color: rgb(29,29,29);
}

.exhibition-items .item .title-and-artist {
    min-width: 240px;
    margin-right: 10px;
    word-wrap: break-word;
    height: 172px;
}

.exhibition-items .item .title-and-artist .artist {
    font-weight: 600;
    font-size: 10pt;
}

.exhibition-items .item .title-and-artist .title {
    margin-top: 14px;
    font-weight: 300;
    font-size: 17pt;
}

.exhibition-items .item .title-and-artist img.avatar {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-right: 5px;
}

.exhibition-items .item .title-and-artist .mint-price {
    font-weight: 200;
    font-size: 11pt;
    margin-top: 10px;
}


.exhibition-items .item .statements {
    font-size: 10pt;
    margin-right: 5px;
    margin-left: 5px;
    /*margin-top: 14px;*/
}

.exhibition-items .item .statements h3 {
    margin-top: 6px;
    margin-bottom: 6px;
    color: #818181
}

.exhibition-items .item .statements p {
    font-weight: 200;
}

.exhibition-items .item .mint-thumbnail-container {
    width: 240px;
    min-width: 240px;
    margin-left: auto;
    overflow: hidden;
}

.exhibition-items .item .mint-thumbnail {
    max-height: 172px;
    margin-left: auto;
    margin-right: auto;
}

.indexer-offline-warning {
    width: 100%;
    font-size: 11pt;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    background-color: pink;
    border: 3px solid pink;
    padding: 3px;
    font-weight: 100;
    /*margin-bottom: 10px;*/
}


@media (max-width: 1069px) {

    .artwork-gallery .hero-card {
        width: 576px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .artwork-gallery .hero-card .live-frame {
        width: 100%;
        position: static;
        position: initial;
        -webkit-transform: none;
                transform: none;
        -webkit-transform: initial;
                transform: initial;
    }

    .artwork-gallery {
        width: 594px;
    }

    .artwork-gallery .hero-card .artwork-details {
        margin-top: 10px;
        height: unset;
        min-height: 300px;
        padding-bottom: 100px;
    }

    .artwork-gallery .hero-card .mint-ui {
        height: 50px;
    }

    .collection {
        width: 594px;
    }

    .exhibition-items .item .title-and-artist {
        min-width: 120px;
    }

    .exhibition-items .item .title-and-artist .artist {
        display: block;
        margin-top: 2px;
    }
}

@media (max-width: 732px) {

    .site-root-link {
        left: 8px;
        width: 30px;
        z-index: 1;
    }

    .site-container {
        width: auto;
        width: initial;
        margin-left: 8px;
        margin-right: 8px;
        left: 0px;
        padding-top: 187px;
    }

    nav ul {
        font-size: 10pt;
        margin-bottom: 50px;    
    }
    nav ul li {
        margin-left: 20px;
    }

    .connect-wallet {
        width: auto;
        width: initial;
    }

    .connect-wallet button {
        font-size: 6pt;
        top: -8px;
    }

    h1 {
        font-size: 16pt;
    }

    .footer {
        font-size: 10pt;
    }

    .footer div.elements:first-child {
        margin-left: 8px;
    }
    .footer div.elements:last-child {
        margin-right: 8px;
    }

    .footer .ewy-logo {
        margin-right: 20px;
    }

    .footer .credits {
        font-size: 8pt;
    }
 
    .body-text {
        font-size: 11pt;
    }

    .connect-wallet {
        margin-left: 10px;
    }

    .connect-wallet .wallet-icon {
        width: 24px;
        height: 34px;
        margin-left: 0;
        margin-left: initial;
    }

    .wallet-dropdown .menu {
        left: -58px;
        z-index: 1;
    }

    /* main page */

    .artwork-card {
        width: 140px;
        margin: 2px 2px 2px 2px;
    }

    .artwork-card .mint-thumbnail {
        width: 140px;
        height: 135px;
    }

    .artwork-card .avatar {
        width: 54px;
        height: 54px;
        left: 43px;
        top: 108px;
    }

    .artwork-card .artwork-details {
        visibility: hidden;
        height: 0px;
    }

    /* artwork/mint page */

    .artwork-gallery {
        width: 344px;
    }
    .artwork-gallery .hero-card {
        width: 344px;
    }

    .artwork-gallery .hero-card .artwork-details {
        /*height: 360px;*/
    }

    .artwork-gallery .hero-card .mint-ui {
        height: 50px;
    }

    .artwork-gallery .hero-card .live-frame .mint-pending .status-text {
        font-size: 12pt;
        bottom: 60px;
    }

    .collection {
        width: 344px;
    }

    /* mints */

    .mints {
        grid-template-columns: repeat(auto-fit, 114px);
        justify-items: center;
    }

    .mints .mint-static {
        width: 109px;
        /*margin: auto 1px 2px 1px;*/
        margin-bottom: 2px;
    }

    .mints .mint-static .mint-thumbnail {
        width: 106px;
    }

    .mints .mint-static .details {
        visibility: hidden;
        height: 0;
    }

    .mints  .mint-static  .buttons {
        visibility: hidden;
        height: 0;
    }

    /* modal */

    .modal-inner {
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 5%;
        font-size: 12pt;
        position: inherit;
        left: inherit;
        -webkit-transform: inherit;
                transform: inherit;
        top: inherit;
        bottom: inherit;
        max-width: inherit;
    }

    .modal-inner button {
        font-size: 10pt;
    }


    .exhibition-items .item .mint-thumbnail-container {
        width: 160px;
        min-width: 160px;
    }

    .exhibition-items .item .mint-thumbnail {
        max-height: 115px;
    }

    .exhibition-items .item {
        /*height: 172px;*/
        flex-direction: column;
        align-items: flex-start;
        padding-right: 15px;
        padding-bottom: 15px;
        border-radius: 6px;
    }    

    .exhibition-items .item .title-and-artist {
        height: auto;
        height: initial;
        margin-bottom: 7px;
        margin-left: 5px;
    }

    .exhibition-items .item .title-and-artist .mint-price {
        font-size: 10pt;
    }

    .exhibition-items .item .mint-thumbnail-container {
        width: unset;
        min-width: unset;
        margin-right: auto;
    }

    .exhibition-items .item .mint-thumbnail {
        max-height: unset;
    }

    .exhibition-items .item .mint-thumbnail-link {
        align-self: flex-start;
    }
}


@media (min-width: 1070px) {
    .artwork-gallery {
        width: 891px;
    }
    .collection {
        width: 891px;
    }
}

@media (min-width: 1400px) {
    .artwork-gallery {
        width: 1188px;
    }
    .collection {
        width: 1188px;
    }
    .artwork-gallery .hero-card {
        width: 1170px;
        height: 780px
    }

    .artwork-gallery .hero-card .live-frame {
        width: 780px;
    }

    .artwork-gallery .hero-card .live-frame .mint-pending .status-text {
        bottom: 150px;
        font-size: 16pt;
    }
}

@media (min-width: 1860px) {
    .artwork-gallery {
        width: auto;
        width: initial;
    }
    .collection {
        width: auto;
        width: initial;
    }
}

