
@media (max-width: 1069px) {

    .artwork-gallery .hero-card {
        width: 576px;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .artwork-gallery .hero-card .live-frame {
        width: 100%;
        position: initial;
        transform: initial;
    }

    .artwork-gallery {
        width: 594px;
    }

    .artwork-gallery .hero-card .artwork-details {
        margin-top: 10px;
        height: unset;
        min-height: 300px;
        padding-bottom: 100px;
    }

    .artwork-gallery .hero-card .mint-ui {
        height: 50px;
    }

    .collection {
        width: 594px;
    }

    .exhibition-items .item .title-and-artist {
        min-width: 120px;
    }

    .exhibition-items .item .title-and-artist .artist {
        display: block;
        margin-top: 2px;
    }
}

@media (max-width: 732px) {

    .site-root-link {
        left: 8px;
        width: 30px;
        z-index: 1;
    }

    .site-container {
        width: initial;
        margin-left: 8px;
        margin-right: 8px;
        left: 0px;
        padding-top: 187px;
    }

    nav ul {
        font-size: 10pt;
        margin-bottom: 50px;    
    }
    nav ul li {
        margin-left: 20px;
    }

    .connect-wallet {
        width: initial;
    }

    .connect-wallet button {
        font-size: 6pt;
        top: -8px;
    }

    h1 {
        font-size: 16pt;
    }

    .footer {
        font-size: 10pt;
    }

    .footer div.elements:first-child {
        margin-left: 8px;
    }
    .footer div.elements:last-child {
        margin-right: 8px;
    }

    .footer .ewy-logo {
        margin-right: 20px;
    }

    .footer .credits {
        font-size: 8pt;
    }
 
    .body-text {
        font-size: 11pt;
    }

    .connect-wallet {
        margin-left: 10px;
    }

    .connect-wallet .wallet-icon {
        width: 24px;
        height: 34px;
        margin-left: initial;
    }

    .wallet-dropdown .menu {
        left: -58px;
        z-index: 1;
    }

    /* main page */

    .artwork-card {
        width: 140px;
        margin: 2px 2px 2px 2px;
    }

    .artwork-card .mint-thumbnail {
        width: 140px;
        height: 135px;
    }

    .artwork-card .avatar {
        width: 54px;
        height: 54px;
        left: 43px;
        top: 108px;
    }

    .artwork-card .artwork-details {
        visibility: hidden;
        height: 0px;
    }

    /* artwork/mint page */

    .artwork-gallery {
        width: 344px;
    }
    .artwork-gallery .hero-card {
        width: 344px;
    }

    .artwork-gallery .hero-card .artwork-details {
        /*height: 360px;*/
    }

    .artwork-gallery .hero-card .mint-ui {
        height: 50px;
    }

    .artwork-gallery .hero-card .live-frame .mint-pending .status-text {
        font-size: 12pt;
        bottom: 60px;
    }

    .collection {
        width: 344px;
    }

    /* mints */

    .mints {
        grid-template-columns: repeat(auto-fit, 114px);
        justify-items: center;
    }

    .mints .mint-static {
        width: 109px;
        /*margin: auto 1px 2px 1px;*/
        margin-bottom: 2px;
    }

    .mints .mint-static .mint-thumbnail {
        width: 106px;
    }

    .mints .mint-static .details {
        visibility: hidden;
        height: 0;
    }

    .mints  .mint-static  .buttons {
        visibility: hidden;
        height: 0;
    }

    /* modal */

    .modal-inner {
        margin-top: 5%;
        margin-left: 5%;
        margin-right: 5%;
        padding: 5%;
        font-size: 12pt;
        position: inherit;
        left: inherit;
        transform: inherit;
        top: inherit;
        bottom: inherit;
        max-width: inherit;
    }

    .modal-inner button {
        font-size: 10pt;
    }


    .exhibition-items .item .mint-thumbnail-container {
        width: 160px;
        min-width: 160px;
    }

    .exhibition-items .item .mint-thumbnail {
        max-height: 115px;
    }

    .exhibition-items .item {
        /*height: 172px;*/
        flex-direction: column;
        align-items: flex-start;
        padding-right: 15px;
        padding-bottom: 15px;
        border-radius: 6px;
    }    

    .exhibition-items .item .title-and-artist {
        height: initial;
        margin-bottom: 7px;
        margin-left: 5px;
    }

    .exhibition-items .item .title-and-artist .mint-price {
        font-size: 10pt;
    }

    .exhibition-items .item .mint-thumbnail-container {
        width: unset;
        min-width: unset;
        margin-right: auto;
    }

    .exhibition-items .item .mint-thumbnail {
        max-height: unset;
    }

    .exhibition-items .item .mint-thumbnail-link {
        align-self: flex-start;
    }
}
