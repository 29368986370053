
.edit-artwork {
    width: 90%;
}

.edit-artwork .view-artwork-link {
    margin: 5px;
}

.edit-artwork .store-button {
    margin-top: 2em;
}

.edit-artwork .store-button .store-error {
    color: red;
    font-weight: 600;
    margin-top: 5px;
}
.edit-artwork .store-button .store-warning {
    color: orange;
    font-weight: 600;
    margin-top: 5px;
}

.edit-artwork .store-button button {
    height: 32px;
    width: 240px;
    font-weight: 800;
    font-size: 18px;
    border-radius: 3px;
}

.edit-artwork h3 {
    margin-top: 5em;    
}

.edit-artwork label {
    margin-top: 20px;
    display: block;
    width: 100%;
}

.edit-artwork input {
    display: block;
    width: 100%;
}

.edit-artwork input.number {
    display: block;
    width: 100px;
}

.edit-artwork input.checkbox  {
    display: inline-block;
    width: auto;
    margin-right: 10px;
}

.edit-artwork textarea {
    display: block;
    width: 100%;
}

.edit-artwork .is_paused label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .isVisible label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .selectSeedOnMint label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .rerolls_enabled label  {
    display: inline-block;
    width: auto;
}

.edit-artwork .royalties {
    border: 1px solid grey;
    padding: 5px;
}

.edit-artwork .royalties label {
    display: inline;
}

.edit-artwork .royalties input {
    display: inline-block;
    width: 50px;
    margin-left: 5px;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 0px;
}

.edit-artwork .royalties input.address {
    width: 300px;
}


.edit-artwork .source-code {
    margin-bottom: inherit;

    display: flex;
    flex-flow: column;
    min-height: 200px;
    height: 700px;
    resize: both;
    overflow: auto;
  
    border: 1px solid black;
    padding: 0px;
}

.edit-artwork .no-change-scripttype-warning {
    display: inline-block;
    font-size: 0.8em;
    color: rgb(48, 133, 48);
    margin-left: 5px;
}

.edit-artwork .artist-tools-github-link {
    font-weight: 600;
}

.edit-artwork .artist-tools-github-link a {
    text-decoration: underline;
}

.edit-artwork .script-editor button.show-preview-button {
    width: 200px;
    height: 24px;
    margin-top: 5px;
    float: right;
}

.edit-artwork .artwork-script-preview {
    width: 400px;
    border: 3px solid white;
    background-color: white;
    position: relative;
}

.edit-artwork button.reload-button {
    position: absolute;
    width: 30px;
    height: 30px;
    right: -40px;
    fill: white;
    stroke: white;
}



.edit-artwork .custom-token-sample {
    box-sizing: border-box;
    resize: none;
    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    resize: none;
    width: 100%;
    margin: 0;
}

.edit-artwork .source-code textarea {
    box-sizing: border-box;

    white-space: pre;
    overflow-wrap: normal;
    overflow-x: scroll;
    resize: none;
    width: 100%;
    margin: 0;
    border: 0;
}


.edit-artwork .source-code .token-sample {
    /*flex: 0 1 auto;*/
    flex: 0 0.1 145px;
    border-bottom: 1px solid rgb(200,200,200);

    background: white;
    color: grey;
        /* The above is shorthand for:
    flex-grow: 0,
    flex-shrink: 1,
    flex-basis: auto
    */
}


.edit-artwork .source-code .editable {
    flex: 1 1 auto;
}

.edit-artwork .extra-button {
    margin-top: 3em;
    margin-right: 1em;
    display: inline-block;
}

.edit-artwork .extra-button button {
    height: 32px;
    width: 240px;
    font-weight: 800;
    font-size: 18px;
    border-radius: 3px;
}


.manage-contract label {
    margin-top: 20px;
    display: block;
    width: 100%;
}

.manage-contract div {
    margin-bottom: 20px;
    display: block;
    width: 100%;
}

.manage-contract input {
    display: block;
    width: 100%;
    margin-bottom: 20px;
}

.manage-contract .base-uris {
    margin-top: 20px;
    padding: 0;
} 

.manage-contract .base-uris label {
    margin: 0;
    display: inline-block;
}

.manage-contract .base-uris input {
    margin: 0;
    display: inline-block;
}

.manage-contract .platform-cut {
    margin-top: 20px;
}

.manage-contract .platform-cut label {
    margin: 0;
    display: inline-block;
}

.manage-contract .platform-cut input {
    margin: 0;
    display: inline-block;
}

.manage-contract .add-test-artwork {
    margin-top: 20px;
}

.add-artwork input {
    width: 300px;
    margin: 10px;
}

